span.icon-upper-arrow {
  position: relative;
  right: -50px;
  top: 4px;
}

.icon-upper-arrow {
  transform: rotate(180deg);
  transition: transform 0.3s ease;
}

.icon-upper-arrow.down {
  transform: rotate(0deg) !important;
}

.sidebar {
  width: 190px;
  height: 100%;
  background-color: #141928;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: width 0.3s ease;
}

.sidebar.collapsed {
  width: 50px;
}

.logo {
  font-size: 24px;
  padding: 20px 0;
  font-weight: bold;
}

.options {
  list-style-type: none;
  padding: 0;
  margin-top: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  color: var(--color-tertiary-grey-light, #b4b9c8) !important;
}

.middle_sidebar {
  width: 98%;
  margin-left: 4px;
}

.collapse-btn:focus {
  outline: none;
}

.jjj {
  margin-top: 10px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
}
.ddd {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

/* css for full sidebar */
.dashboard-container {
  display: flex;
  background: #f7f8f9;
}
.facer {
  border-bottom: none !important;
  padding-bottom: 10px;
}
.roppeee a.dropdown-item {
  padding: 10px 20px !important;
}
.roppeee .dropdown-menu {
  margin: 0px;
  padding: 0px;
}
.roppeee .dropdown-menu {
  border: 1px solid #efefef;
}

.roppeee .dropdown-item.active,
.roppeee .dropdown-item:active {
  color: none !important;
  text-decoration: none;
  background-color: none !important;
}

.sidebar-main {
  background-color: #141928;
  min-height: 100vh;
}

.header-dasboard {
  flex-grow: 1;
}

.header-dasboard {
  flex-grow: 1;
}

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}

/* css for full sidebar */

.left-section h3 {
  color: #363636;
  font-family: Oxygen;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  margin-top: 22px;
}

.header {
  background-color: transparent;
  color: #ffffff;
  padding: 10px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.user_sidebar {
  margin-right: 0px !important;
}

.header-title {
  margin: 0;
  font-size: 24px;
}

.header-nav {
  list-style-type: none;
  padding: 0;
  display: flex;
  gap: 15px;
}

.header-nav-item {
  cursor: pointer;
}

.header-user {
  cursor: pointer;
}

a.option-link {
  text-decoration: none;
  /*   color: #fff; */

  font-size: 15px !important;

  font-weight: 400;
  line-height: 150%;
}

.logo-image-small {
  width: 30px;
}

.option-link,
.option-link:visited {
  color: inherit;
  /* Your desired color */
  text-decoration: none;
  display: inline-block;
  vertical-align: middle;
  -webkit-border-top-left-radius: 10px;
  -webkit-border-bottom-left-radius: 10px;
  -moz-border-radius-topleft: 10px;
  -moz-border-radius-bottomleft: 10px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.option:hover,
.option.active {
  border-radius: 20px 0px 0px 20px;
  background: var(--Color-White-Off, #f7f8f9);
  -webkit-border-top-left-radius: 10px;
  -webkit-border-bottom-left-radius: 10px;
  -moz-border-radius-topleft: 10px;
  -moz-border-radius-bottomleft: 10px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  color: #141928 !important;
}

.option-icon:hover::before {
  color: #9aca3c;
}

/* .option-link:visited {

  color: #141928 !important;
} */

a.option-link {
  font-size: 15.5px !important;
}

.arrow-icon {
  width: 24px;
  height: auto;
  transition: transform 0.3s ease-in-out;
  margin-right: 14px;
}

.collapse-btn.collapsed .arrow-icon {
  transform: rotate(180deg);
}

.btn-custom {
  background: none;
  border: none;
  color: #fff;
  color: var(--color-tertiary-grey-mid, #787d8c);
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  margin-top: 0px;
  /*     position: ; */
  bottom: 20px;
}

.option.border-bottom {
  border-bottom: 1px solid #3c4150 !important;
  margin-bottom: 8px;
  padding-bottom: 8px;
}

.options .collapsed {
  width: 100%;
  margin-left: 0px;
}

/*   header */

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /*  padding: 10px 30px;
  padding-right: 0px; */
}

.left-section,
.right-section {
  display: flex;
  align-items: center;
}

.left-section p,
.left-section a,
.right-section div,
.right-section svg {
  margin-right: 10px;
}

.search-box {
  position: relative;
}

.search-box input {
  padding-right: 30px;

  border-radius: 5px;
  border: 0.5px solid var(--Color-Tertiary-Grey-light, #b4b9c8);
  background: var(--Color-White-Pure, #fff);
  height: 35px;

  color: #fff;
  color: var(--color-tertiary-grey-light, #b4b9c8);
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
  width: 211px;
  padding-left: 15px;
  padding-right: 38px;
}

.search-box svg {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.user-image {
  border-radius: 50%;
  width: 40px;
  height: 40px;
}

.left-section p {
  margin-top: 20px;
  color: var(--color-white-pure, #fff);

  font-size: 14px;

  font-weight: 400;
  line-height: 150%;
}

.left-section {
  gap: 15px;
}

.icon-pencil,
.icon-eye,
.icon-search,
.icon-bell {
  font-size: 22px !important;
}

.left-section a {
  text-decoration: none;
}

span.icon-eye {
  position: relative;
  top: 0px;
}

/* .left-section input[type="text"] {
    background: #141928; border-bottom: 1px solid #B4B9C8; color: #fff; border-top: 0px; border-left: 0px; border-right: 0px; font-size: 14px;
} */
.left-section input[type="text"] {
  background: #141928;
  border-bottom: none;
  color: #fff;
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  font-size: 14px;
  color: #fff !important;
  width: 184px;
}

.left-section input[type="text"]::placeholder {
  color: #fff;
}

.right-section {
  gap: 15px;
}

.search-box {
  position: relative;
}

span.icon-search {
  position: absolute;
  right: 11px;
  top: 7px;
}

.bell_notification {
  margin-top: 6px;
  position: relative;
}

span.notification_count {
  position: absolute;
  top: -6px;
  background: red;
  height: 14px;
  width: 14px;
  border-radius: 100%;
  padding: 0px;
  font-size: 9px;
  left: 14px !important;
  text-align: center;
}

/* dashboard */

.block {
  border: 1px solid #ddd;
  border-radius: 0 20px 0 0;
  position: relative;
  margin-bottom: 20px;
}

.icon-bg {
  background-color: #4caf50;
  /* Green */
  border-radius: 100%;
  padding: 10px;
  position: absolute;
  top: 0;
  left: 0;
}

.icon {
  color: white;
}

.chart-space {
  position: absolute;
  top: 0;
  right: 0;
  width: 50%;
  height: 50px;
}

.number {
  font-size: 24px;
  margin: 60px 0 10px;
}

.block {
  border: 1px solid #ddd;
  border-radius: 5px;
  position: relative;
  margin-bottom: 20px;
  padding: 0px 14px !important;
}

.icon-bg {
  background-color: #4caf50;
  border-radius: 100%;
  padding: 8px;
  position: absolute;
  top: 18px;
  left: 14px;
  font-size: 27px;
}

.icon-vector4,
.icon-vector0,
.icon-Vector1,
.icon-Vector2 {
  /* Include your styling or path to the icons here */
}

.chart-space {
  position: absolute;
  top: 0;
  right: 0;
  width: 50%;
  height: 50px;
}

.number {
  color: var(--color-secondary-dark, #141928);
  font-family: "Roboto", sans-serif;
  font-size: 24px;
  font-weight: 700;
  margin-top: 70px;
}

.block-color1 .block {
  background: #cce4ff;
}

.block-color2 .block {
  background: var(--pastel-light-orange, #ffeacc);
}

.block-color3 .block {
  background: var(--pastel-light-green, #d4eeda);
}

.block-color4 .block {
  background: var(--pastel-light-red, #fbdbdb);
}

.block-color1 .icon-bg {
  background: var(--pastel-dark-blue, #007aff);
}

.block-color2 .icon-bg {
  background: var(--pastel-dark-orange, #ff9600);
}

.block-color3 .icon-bg {
  background: var(--pastel-dark-green, #28ac47);
}

.block-color4 .icon-bg {
  background: var(--pastel-dark-red, #ed4949);
}

.blockp {
  color: var(--color-secondary-dark-light, #505564);
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  margin-top: -9px !important;
}

.chart-space {
  position: absolute;
  top: 22px;
  right: 9px;
  width: 50%;
  height: 50px;
}

.mutual_blocks {
  border-radius: 10px;
  background: var(--color-white-pure, #fff);
  box-shadow: 0px 0px 30px 0px rgba(80, 143, 244, 0.1);
  padding: 30px;
}

.tick-label {
  color: #b4b9c8;
  text-align: center;
  font-family: Oxygen, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}

.heading_inline {
  justify-content: space-between;
  margin-bottom: 15px;
}

.left_head h2 {
  color: var(--color-secondary-dark-mid, #3c4150);

  font-size: 20px;

  font-weight: 700;
}

.peepnes {
  padding: 0px !important;
  padding-left: 10px !important;
}
.header-dasboard.main_container {
  padding-bottom: 10px;
}

.sattert-4 {
  margin-top: 10px !important;
}

.details-link {
  color: var(--color-tertiary-grey, #646978);
  text-align: right;

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  text-decoration-line: underline;
}

.mean_median {
  justify-content: end;
  gap: 20px;
  margin-top: 20px;
}

.left_mean,
.right_mean {
  color: var(--color-tertiary-grey-mid, #787d8c);

  font-size: 14px;

  font-weight: 400;
  line-height: 150%;
}

.left_mean span {
  background: #b2d235;
  border: 0px solid #b2d235;
  padding: 0px 19px;
  font-size: 3px !important;
  margin-right: 10px;
  margin-top: -5px;
  position: relative;
  top: -4px;
}

.right_mean span {
  background: #80c342;
  border: 0px solid #80c342;
  padding: 0px 19px;
  font-size: 3px !important;
  margin-right: 10px;
  margin-top: -5px;
  position: relative;
  top: -4px;
}

.teammate {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  border-bottom: 1px solid #b4b9c8;
  padding-bottom: 23px;
}

.teammate-img {
  border-radius: 50%;
  width: 54px;
  height: 54px;
}

.teammate-info {
  margin-left: 12px;
}

.teammate-info h3 {
  color: var(--color-tertiary-grey-mid, #787d8c);
  font-size: 16px;
  font-weight: 400;
  line-height: 4px;
  margin-top: 12px;
  font-family: "Oxygen", sans-serif !important;
}

.sales-link {
  color: var(--color-tertiary-grey-light, #b4b9c8);

  font-size: 12px;

  font-weight: 400;
  line-height: 150%;
  text-decoration-line: underline;
}

.team_mamet {
  padding: 20px;
}

.icon_mut {
  font-size: 22px !important;

  position: relative;
  top: 2px;
}

.icon_text_area {
  margin-top: 28px;
}

.borrom_blocks h3 {
  color: var(--color-tertiary-grey-mid, #787d8c);
  margin-bottom: 20px;

  font-size: 16px;

  font-weight: 700;
}

.borrom_blocks p {
  color: var(--color-tertiary-grey, #646978);

  font-size: 16px;

  font-weight: 400;
  line-height: 150%;
}

.top_set {
  margin-top: -12px;
}

.text_set {
  color: var(--color-tertiary-grey, #646978);
  font-size: 14px !important;
  font-weight: 400;
  line-height: 150%;
  margin-left: 10px;
}

.iconing {
  margin-top: 15px;
}

.broker-section {
  height: 330px !important;
  overflow: auto;
}
.board_row {
  position: sticky;
  top: 0px;
  background: #fff;
  padding-bottom: 5px;
}

.single_social a {
  border-radius: 4px;
  border: 1px solid var(--color-secondary-dark-light, #505564);
  background: var(--color-secondary-dark-light, #505564);
  height: 40px;
  width: 40px;
  color: #fff;
  font-size: 18px;
  text-align: center;
  padding: 6px;
  display: inline-block;
}

.share_links.d-flex {
  gap: 8px;
}

.social_area p {
  color: var(--color-tertiary-grey, #646978);

  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
}

.followme {
  margin-top: 54px;
}

.followme h4 {
  color: var(--color-tertiary-grey-mid, #787d8c);

  margin-bottom: 30px;
  font-size: 16px;

  font-weight: 700;
}

.teammate:last-child {
  border-bottom: none;
}

.blog_only .option-icon {
  margin-left: -15px;
}

.option-icon:before {
  color: #b4b9c8;
}

.option-icon:hover::before {
}

/* new-dashboard-css */

.board_row {
  justify-content: space-between;
}

.left_dash_heading {
  color: var(--Color-Secondary-Dark, #141928);

  font-size: 20px;

  font-weight: 700 !important;
  font-family: "Roboto", sans-serif !important;
}

.right_dash_link {
  color: var(--Color-Tertiary-Grey, #646978);

  font-size: 14px;

  font-weight: 400;
  line-height: 150%;
}

.listin_table .table-borderless td,
.listin_table .table-borderless th {
  border: 0;
  background-color: transparent;
}

.list_images img {
  height: 25px;
  width: 25px;
  border-radius: 100px;
  margin-left: -3px;
}

.list_images {
  margin-left: -3px;
}

.listin_table {
  margin-top: 20px;
}

.listin_table td {
  color: var(--Color-Secondary-Dark-Mid, #3c4150);
  padding: 6px 0px;

  /* Body Small */
  font-family: Oxygen;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  /* 21px */
}

.listin_table th {
  color: var(--Color-Tertiary-Grey-light, #b4b9c8);
  padding-bottom: 15px;

  /* Body Small */
  font-family: Oxygen;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  /* 21px */
}

.rel_heading {
  color: var(--Color-Secondary-Dark-Mid, #3c4150);

  font-size: 20px;

  font-weight: 700;
  font-family: "Roboto", sans-serif !important;
}

.real_mls {
  flex-wrap: wrap;
  justify-content: space-between;
}

.mls_bts_row {
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
}

.clopping .left_real_list {
  width: 48%;
}

.right_real_mls {
  width: 48%;
}

.clopping .rel_links {
  color: var(--Color-Tertiary-Grey, #646978);
  font-family: Oxygen;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  text-decoration-line: underline;
  margin-top: 5px;
}

.clopping .number_left {
  color: var(--Color-Secondary-Dark-light, #505564);
  font-family: Oxygen;
  font-size: 64px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.clopping .number_right {
  color: var(--Color-Secondary-Dark-light, #505564);
  font-family: Oxygen;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.clopping .numbers_mls {
  text-align: center;
  margin-top: 50px;
  margin-bottom: 30px;
}

.clopping .mls_text {
  color: var(--Color-Tertiary-Grey, #646978);
  font-family: Oxygen;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 10px;
}

.clopping .mls_text a {
  color: var(--Color-Primary-Blue, #22a9e0);
  font-family: Oxygen;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: underline;
}

.clopping .mls_listing_images {
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
}

.singleadd_mls img {
  width: 100px;
  width: 100px;
  border-radius: 500px;
}

.mls_listing_images {
  margin-top: 45px;
}

.clopping .left_mls_image img {
  border-radius: 5px;
  border: 1px solid var(--Color-White-Blue, #f0f5ff);
  width: 45px;
  height: 45px;
}

.clopping .mls_looping {
  margin-top: 50px;
}

.clopping .right_mls_data h2 {
  color: var(--Color-Tertiary-Grey, #646978);

  /* Heading Span */
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 0px;
}

.clopping .right_mls_data p {
  color: var(--Color-Tertiary-Grey-light, #b4b9c8);
  font-family: Oxygen;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  /* 21px */
  text-decoration-line: underline;
  margin-top: 4px;
  margin-bottom: 5px;
}

.clopping .right_mls_data h3 {
  margin-top: 3px;
  color: var(--Color-Tertiary-Grey, #646978);
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.clopping .right_mls_data {
  margin-top: 3px;
}

.clopping .single_mls_detail {
  gap: 15px;
  padding: 0px;
  padding-bottom: 10px;
  border: none;
}

.single_mls_detail {
  margin-top: 10px;
}

.login_stripe {
  color: var(--Color-Primary-Green, #80c342);
  text-align: center;

  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  border-radius: 4px;
  border: 1px solid var(--Color-Primary-Green, #80c342);
  box-shadow: 0px 0px 10px 0px rgba(182, 182, 182, 0.5);
  padding: 13px 30px;
  background-color: transparent !important;
}

.left_stripe {
  align-items: center;
  gap: 15px;
  width: 67%;
}

.right_stripe {
  width: 20%;
}

.stripe_area {
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
  gap: 70px;

  align-items: center;
  margin-top: 70px;
  margin-bottom: 10px;
}

.full_striope {
  background: var(--Color-White-Off, #f7f8f9);
  width: 80%;
  margin: 0 auto;
}

.chart_values {
  text-align: center;
}

.chart_values h3 {
  color: var(--Color-Tertiary-Grey, #646978);
  text-align: center;

  /* Heading 3 */
  font-family: Roboto;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.chart_values p {
  color: var(--Color-Tertiary-Grey-light, #b4b9c8);
  text-align: center;
  font-family: Oxygen;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  /* 30px */
}

.martgin_srtting {
  margin-top: -20px;
}

.chart_values {
  margin-top: 20px;
}

.p_chart {
  position: relative;
  width: 100%;
  height: 200px;
}

.seats_vacant {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 2em;
  color: #333;
  color: var(--Color-Tertiary-Grey, #646978);
  text-align: center;

  /* Heading 2 */
  font-family: Roboto;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.mutual_blocks {
  border-radius: 10px;
  background: var(--color-white-pure, #fff);
  box-shadow: 0px 0px 30px 0px rgba(80, 143, 244, 0.1);
  padding: 10px;
  height: 100%;
}

.p_chart {
}

.bar_chart .recharts-text {
  fill: #4a4a4a;
  color: var(--Color-Tertiary-Grey, #646978);
  text-align: center;

  /* Body Small */
  font-family: Oxygen;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  /* 21px */
}

.bar_chart .recharts-rectangle {
  stroke: none;
}

/* Tooltip custom style */
.bar_chart .recharts-tooltip-wrapper {
  outline: 1px solid #efefef;
  background-color: #fff;
  border-radius: 10px;
  padding: 5px;
}

.bar_chart {
  margin-top: 25px;
}

.bar_charty {
  padding: 0px !important;
}

.bar_chart_only {
  padding: 10px;
  padding-bottom: 0px;
}

/* new-dashboard-css */

.mls_looping.clopping {
  margin-top: 25px;
}

@media (min-width: 1500px) and (max-width: 1800px) {
  .wid_adjust {
    width: 78% !important;
  }

  /*  .wid_set {
      width: 22% !important;
  } */
  .blockp {
    font-size: 15px;
  }

  .number {
    font-size: 22px;
  }

  .teammate-info h3 {
    font-size: 15px;
  }

  .borrom_blocks p {
    font-size: 15px;
  }

  .social_area p {
    font-size: 15px;
  }

  .option-icon {
    font-size: 20px;
  }

  a.option-link {
    font-size: 14.5px !important;
  }
}

@media (min-width: 1366px) and (max-width: 1499px) {
  a.option-link {
    font-size: 15.5px !important;
  }

  .wid_adjust {
    width: 78% !important;
  }

  /*   .wid_set {
        width: 22% !important;
    } */
  .blockp {
    font-size: 15px;
  }

  .number {
    font-size: 22px;
  }

  .teammate-info h3 {
    font-size: 15px;
  }

  .borrom_blocks p {
    font-size: 15px;
  }

  .social_area p {
    font-size: 15px;
  }

  .option-icon {
    font-size: 20px;
  }
}

@media (min-width: 1280px) and (max-width: 1365px) {
  .chart-space {
    position: absolute;
    top: 14px;
    right: 16px;
  }

  .number {
    font-size: 20px !important;
  }

  .icon-bg {
    font-size: 22px !important;
  }

  .wid_adjust {
    width: 76% !important;
  }

  /*    .wid_set {
        width: inherit !important;
    } */
  .blockp {
    font-size: 15px;
  }

  .number {
    font-size: 22px;
  }

  .teammate-info h3 {
    font-size: 15px;
  }

  .borrom_blocks p {
    font-size: 15px;
  }

  .social_area p {
    font-size: 15px;
  }

  a.option-link {
    font-size: 14.5px !important;
  }
}

.custom_structure.undefined {
  width: 18%;
}

/* Mobile styles */
.mobile_only {
  display: none;
}

.toggle-button {
  display: none;
}

.mutual_blocks.team_mamet.mater {
  min-height: 200px;
  height: 390px;
  overflow: auto;
}

.mater .heading_inline {
  position: sticky;
  top: -30px;
  background: #fff;
  padding: 10px 0px;
}

.mater .teammate-info h3 {
  margin-bottom: 5px;
}
.teammate-info h3 {
  margin-top: 2px;
}

.mater .teammate {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  border-bottom: 1px solid #b4b9c8;
  padding-bottom: 12px;
}
.mater .heading_inline {
  margin-bottom: 5px;
}
.mutual_blocks.team_mamet.mater {
  padding-top: 9px;
}

@media (min-width: 992px) and (max-width: 1279px) {
  .wid_adjust {
    width: 100% !important;
  }

  .wid_set {
    width: 100% !important;
    margin-top: 10px !important;
  }
}

@media (min-width: 768px) and (max-width: 1279px) {
  .sidebar {
    width: 169px !important;
  }

  .middle_sidebar {
    width: 87% !important;
    margin-left: 22px !important;
  }

  .number {
    font-size: 19px !important;
  }

  .blockp {
    font-size: 14px !important;
  }

  .borrom_blocks {
    margin-bottom: 10px;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  .bar_charty {
    padding: 0px !important;
    margin-top: 60px !important;
  }
  .borrom_blocks .listin_table td,
  .borrom_blocks .listin_table th {
    padding: 10px 15px;
  }
  .settter {
    margin-top: 40px !important;
  }
  .wid_adjust .col-lg-3 {
    width: 49%;
  }

  .right-section {
    width: 100%;
    margin-top: 20px;
    justify-content: flex-end;
  }

  .left-section p {
    font-size: 12px;
  }

  .mobile_only {
    display: block;
  }

  .sidebar {
    display: none;
  }

  .sidebar.visible {
    display: block;
  }

  .toggle-button {
    display: inline-block;
    /* your styling for button */
  }

  button.toggle-button {
    position: absolute;
    top: 16px;
    left: 11px;
  }

  button.collapse-btn.btn-custom {
    display: none;
  }

  .dashboard {
    padding-left: 0px;
    padding-right: 0px;
  }

  .right-section {
    width: 100%;
    margin-top: 20px;
  }

  .left-section {
    gap: 7px;
    width: 100%;
    margin-top: -9px;
    justify-content: flex-end;
  }

  .header {
    flex-wrap: wrap;
  }

  .icon-bg {
    padding: 8px;

    top: 14px;
    left: 10px;
    font-size: 19px;
  }

  .number {
    font-size: 18px;
  }

  .blockp {
    font-size: 14px;
  }

  .block {
    margin-bottom: 9px;
  }

  .left_head h2 {
    font-size: 17px;
  }

  .left_mean,
  .right_mean {
    font-size: 11px;
  }

  .mutual_blocks {
    padding: 13px;
  }

  .left_mean span {
    padding: 0px 6px;
  }

  .right_mean span {
    padding: 0px 6px;
  }

  .team_mamet {
    margin-top: 20px;
  }

  .mutual_blocks {
    padding: 13px;
    margin-top: 20px;
  }

  .borrom_blocks p,
  .social_area p {
    font-size: 14px;
  }

  .single_social a {
    height: 35px;
    width: 35px;

    font-size: 15px;
  }

  .search-box input {
    width: 100%;
  }

  .header {
    padding-right: 0px;
    padding-left: 0px;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .bar_charty {
    padding: 0px !important;
    margin-top: 60px !important;
  }
  .borrom_blocks .listin_table td,
  .borrom_blocks .listin_table th {
    padding: 10px 15px;
  }
  .settter {
    margin-top: 40px !important;
  }

  .right-section {
    width: 100%;
    margin-top: 20px;
    justify-content: flex-end;
  }

  .search-box input {
    width: 158px;
  }

  .header {
    padding-right: 0px;
    padding-left: 13px;
  }

  .left-section p {
    font-size: 12px;
  }

  .mobile_only {
    display: block;
  }

  .sidebar {
    display: none;
  }

  .sidebar.visible {
    display: block;
  }

  .toggle-button {
    display: inline-block;
    /* your styling for button */
  }

  button.toggle-button {
    position: absolute;
    top: 16px;
    left: 11px;
  }

  button.collapse-btn.btn-custom {
    display: none;
  }

  .dashboard {
    padding-left: 0px;
    padding-right: 0px;
  }

  .right-section {
    width: 100%;
    margin-top: 20px;
  }

  .left-section {
    gap: 7px;
    width: 100%;
    margin-top: -9px;
    justify-content: flex-end;
  }

  .header {
    flex-wrap: wrap;
  }

  .icon-bg {
    padding: 8px;

    top: 14px;
    left: 10px;
    font-size: 19px;
  }

  .number {
    font-size: 18px;
  }

  .blockp {
    font-size: 14px;
  }

  .block {
    margin-bottom: 9px;
  }

  .left_head h2 {
    font-size: 17px;
  }

  .left_mean,
  .right_mean {
    font-size: 11px;
  }

  .mutual_blocks {
    padding: 13px;
  }

  .left_mean span {
    padding: 0px 6px;
  }

  .right_mean span {
    padding: 0px 6px;
  }

  .team_mamet {
    margin-top: 20px;
  }

  .mutual_blocks {
    padding: 13px;
    margin-top: 20px;
  }

  .borrom_blocks p,
  .social_area p {
    font-size: 14px;
  }

  .single_social a {
    height: 35px;
    width: 35px;

    font-size: 15px;
  }
}

.main-logo {
  text-align: center;
}

.sidebar-main {
  position: relative;
}

/* Sidebar.css */

.setting_only {
  display: inherit;
}

ul.sub-options {
  margin: 0px;
  padding: 0px;
}

li.sub-option {
  list-style-type: none;
  text-align: left;
}

.sub-options a {
  text-decoration: none;
  color: var(--color-tertiary-grey-light, #b4b9c8) !important;
  font-size: 15px !important;
  font-weight: 400;
  line-height: 150%;
}

.icon-Setting {
  margin-left: -3px;
}

.setting_only a.option-link.active {
  margin-top: -7px;
}

li.sub-option {
  list-style-type: none;
  text-align: left;
  line-height: 39px;
  display: flex;
  vertical-align: middle;
  align-items: center;
  gap: 8px;
  margin: 10px;
  margin-left: 0px;
  margin-top: 17px;
}

.setting_only span {
  font-size: 21px !important;
}

svg.svg-inline--fa.fa-chevron-down.icon-chevron-down.rotated {
  text-align: revert;
  margin-left: 58px;
}

.icon-chevron-down.rotated {
  transform: rotate(180deg);
  transition: transform 0.3s ease-out;
  margin-left: 58px !important;
  font-size: 12px;
  color: #787d8c;
}

svg.svg-inline--fa.fa-chevron-down.icon-chevron-down {
  font-size: 12px;
  color: #787d8c;
  margin-left: 58px;
}

.sub-options li:hover {
}
