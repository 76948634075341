.option-link {
  width: 100%;
  text-align: left;
  cursor: pointer;
  display: flex !important;
  align-items: center;
  justify-content: left;
  padding: 10px;
  margin-top: 10px;
}

.options .option-link:hover {
  background-color: #fff;
  color: #141928 !important;
}

.option-icon {
  margin-right: 10px;
}

.options .option-link.active {
  background-color: #fff;
  color: #141928 !important;
}

@media (min-width: 1025px) and (max-width: 1279px) {
  .mutual_blocks {
    height: 100%;
  }
  .sidebar {
    width: 100% !important;
  }
  .sidebar-main {
    position: relative;
  }

  .header-dasboard {
    flex-grow: 1;
    width: 60%;
    margin-left: 0%;
  }
  .sidebar-main {
    background-color: #141928;
  }
  .middle_sidebar {
    width: 88% !important;
    margin-left: 22px !important;
  }
  .mutual_blocks {
    margin-bottom: 20px;
    margin-top: 20px;
    height: inherit;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .sidebar {
    width: 100% !important;
  }
  .sidebar-main {
    position: relative;
  }

  .header-dasboard {
    flex-grow: 1;
    width: 60%;
    margin-left: 0%;
  }
  .sidebar-main {
    background-color: #141928;
  }
  .mt33 {
    margin-top: 10px !important;
  }
  .middle_sidebar {
    margin-left: 30px !important;
  }
}

@media (min-width: 320px) and (max-width: 767px) {
  .middle_sidebar {
    width: 100%;
    margin-left: 30px;
  }
  button.toggle-button {
    position: absolute;
    top: 16px;
    left: 11px;
    border: 1px solid #c5c5c5;
    color: #ffffff;
    background: #80c342;
  }
}
