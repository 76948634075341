.login-header {
   
    height: 100px !important;
    padding-top: 11px;
}

.mobile {
  
    margin-top: 23px;
}

.right_principal {
    width: 81%;
}
.left_principal {
    width: 15%;
}

.top_principal{ flex-wrap: wrap; justify-content: space-between; border-radius: 10px;
    background: var(--color-white-pure, #FFF);
    box-shadow: 0px 0px 30px 0px rgba(80, 143, 244, 0.10); padding: 32px;}

.right_principal .single_entry label{ font-size: 16px;}

.custom_principal_page{ padding: 50px !important;}

.principal_text_area {
    margin-top: 20px;
}
.principal_text_area h3{ color: var(--color-secondary-dark, #141928);
    font-family: Oxygen;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 24px */}
.principal_text_area .area_control{ color: var(--color-tertiary-grey-mid, #787D8C);

    font-family: Oxygen;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; min-height: 104px !important;}

    .left_principal {
        position: relative;
        text-align: center;
    }
    
    .left_principal img.responsive_image {
        width: auto;
        max-width: 100%;
        height: auto;
        display: block; 
        margin: 0 auto; 
    }
    
    .edit-icon {
        position: absolute;
        top: 50%; 
        left: 50%; 
        transform: translate(-50%, -50%);
        cursor: pointer;
       
    }
    

   
    .responsive_image{ height: 140px !important; width: 140px !important; border-radius: 500px !important;}

    .edit-icon-image {
        height: 30px;
        width: 30px;
        border-radius: 5px;
        background: var(--color-white-off, #F7F8F9);
        margin-top: -10px;
        position: relative;
        top: -40px;
        right: -54px;
    }

        .left_principal  img{ height: 20px; width: 20px;}

        .contact_detail h3{ color: var(--color-secondary-dark-light, #505564);

            /* Heading 6 */
            font-family: Roboto;
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;}

            .left_contact_dt{ width: 48%;}

            .right_cont_dt{ width: 48%;}

            .contact_detail.title_bar_setting {
                width: 100%;
            }

            .contract_rows { justify-content: space-between;}


            .selectors{ margin-top: 50px;}

            .selectors h3{ margin-bottom: 0px !important;}

            .selectors .contract_rows{ justify-content: flex-start;}
            .selectors .left_contact_dt{ width: inherit;}
            .selectors .right_cont_dt{ width: inherit; margin-left: 20px;}









            .custom-radio-container {
    display: inline-block;
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    font-size: 16px;
    user-select: none;
}

.custom-radio-container .custom-radio {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.radio-checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 22px;
    width: 22px;
   
    border-radius: 50%;
    border: 2px solid #80C342; 
}

.custom-radio-container .custom-radio:checked ~ .radio-checkmark {
    background-color: #fff;
}


.radio-checkmark:after {
    content: "";
    position: absolute;
    display: none;
}


.custom-radio-container .custom-radio:checked ~ .radio-checkmark:after {
    display: block;
}


.custom-radio-container .radio-checkmark:after {
    top: 3px;
    left: 3px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: #80C342; 
}



.selector_btn .btn{ border-radius: 4px;
    background: var(--color-primary-green, #80C342) !important;
    box-shadow: 0px 0px 10px 0px rgba(182, 182, 182, 0.50); margin-top: 50px;text-align: center;}

    .selector_btn {
        text-align: center;
        width: 100%;
    }



    /* slider */

    .hidden_selector {
        margin-left: 18px; width: 300px;
    }
    input#myRange {
       
        position: relative;
        top: -10px; width: 300px;
    }



    /* SliderStyles.css */
.rc-slider-track {
    background-color:#80C342 !important;
    height: 10px;
}

.rc-slider-handle {
    border-color: #80C342 !important;
    height: 20px;
    width: 20px;
    margin-left: -10px; 
    margin-top: -5px; 
    background-color: #80C342;
}

.rc-slider-rail {
    background-color: #F0F5FF !important;
    height: 10px;
}

.right_principal .form-control {
   
    display: inline-block;
    margin-left: 10px;
}
.rc-slider-handle {
    position: absolute;
    z-index: 1;
    width: 14px;
    height: 14px;
    margin-top: -5px;
    background-color: #80C342 !important;
    border: solid 2px #80C342 !important;
    border-radius: 50%;
    cursor: pointer;
    cursor: grab;
    opacity: 1 !important;
    touch-action: pan-x;
}

.rc-slider {
    position: relative;
    width: 95% !important;
    height: 14px;
    padding: 0px !important;
    border-radius: 6px;
    touch-action: none;
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    margin-left: 24px !important;
}



.form-control::placeholder {
    color: #c5c5c5; 
    opacity: 1;
}


.broker_edit_profile .contact_detail { margin-top: 30px;}

.broker_edit_profile .principal_text_area {
    margin-top: 20px;
    width: 100%; margin-bottom: 30px;
}
.broker_edit_profile .right_principal .form-control{ margin-left: 0px;}

.this_page_only label{ color: var(--color-secondary-dark-mid, #3C4150);

    
    font-size: 16px;
   
    font-weight: 700;
   }


   .rc-slider-handle {
    position: absolute;
    z-index: 1;
    width: 14px;
    height: 14px;
    margin-top: -5px;
    background-color: #80C342 !important;
    border: solid 2px #4f9f05!important;
    border-radius: 50%;
    cursor: pointer;
    cursor: grab;
    opacity: 1 !important;
    touch-action: pan-x;
    top: 3px;
    left: 80px;
    border: 1px solid #efefef;
}

.eye_set{ position: relative;}
.password_eye {
    position: absolute;
    right: 10px;
    top: 69px;
}
.password_eye img{ height: 20px; width: 20px;}
.eye_set .form-control{ padding-right: 30px;}

.broker_profile_only{ width: 100%; max-width:100%}

.tds{ margin-top: 50px !important;}

.tds .select__control{     color: var(--color-tertiary-grey-mid, #787D8C);
    font-size: 14px;
    font-weight: 400;
    border-radius: 4px;
    border: 1px solid var(--color-white-blue, #F0F5FF);
    background: var(--color-white-pure, #FFF);
    box-shadow: 0px 0px 20px 0px rgba(225, 225, 225, 0.25);
    min-height: 51px; max-height: fit-content;
    margin-top: 10px;}

    .select__multi-value__label{ background:var(--color-primary-green, #80C342) !important;color: #fff !important;}
    .select__multi-value__remove.css-12a83d4-MultiValueRemove {
        background: #498512;
        color: #fff;
    }

    .change_passs {
        position: absolute;
        right: 0;
        top: 27px; color: var(--Color-Primary-Blue, #22A9E0);
        text-align: right;
        cursor: pointer;        
        font-size: 12px;
        font-weight: 400;
        
    }

    .modal_closer img{ height: 20px; width: 20px;}
    .milko .modal-content {
        padding: 20px;
    }


    .profile_entries{ text-align: center;}

    .profile_entries .tab-button{ color: var(--Color-Secondary-Dark, #141928);
        text-align: center;
        
        /* Heading 5 */
        font-family: Roboto;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal; }

       .profile_entries .tab-button::after{background-color: #141928;}
       .image_uploader{ padding: 20px 0px; padding-top: 30px;}
       .image_upload_profile img{ height: 140px; width: 140px; border-radius: 500px; background: #efefef;}
       .image_upload_profile{ position: relative;}

       .image_edit_icon img {
        height: 30px;
        width: 30px;
        border-radius: 5px !important;
        border: 1px solid #d1d1d1;
    }
    .image_edit_icon {
        position: absolute;
        right: 119px;
        bottom: 9px;
    }
    .single_fields {
        position: relative;
        margin-top: 18px;
    }

    .single_fields .form-control{ border-radius: 5px !important;
        border: 1px solid var(--Color-White-Blue, #F0F5FF); color: var(--Color-Tertiary-Grey, #646978) !important;

        font-size: 14px !important;
        font-weight: 400 !important; border-left: 1px solid var(--color-white-blue, #F0F5FF) !important; padding-left: 40px !important;  height: 44px !important; margin-left: 0px !important;
      }

      .u_icon {
        position: absolute;
        top: 39px;
        left: 10px;
    }

    .change_password{ color: var(--Color-Tertiary-Grey-light, #B4B9C8);
        text-align: right;font-size: 12px;font-weight: 400; border: none; background: none;
       }

       button.change_password {
        text-align: right;
        width: 100%;
        margin-top: 9px;
    }
    .logout {
        border-radius: 4px;
        background: var(--Color-Primary-Blue, #22A9E0) !important;
        box-shadow: 0px 0px 10px 0px rgba(182, 182, 182, 0.50);
        padding: 11px 64.405px 11px 64.595px;
        border: none;
        color: #fff; text-align: center;
       
      
    }

    .make_sure{ text-align: left; color: #3C4150;

      
        font-size: 14px;
        font-style: normal;
        font-weight: 400; margin-top: 30px; margin-bottom: 30px;
        }

      

        .change_pass {
            text-align: left;
            margin-top: -24px;
        }

        .passw_changing label{ color: var(--Color-Secondary-Dark-light, #505564);

            /* Heading Span */
            font-family: Roboto;
            font-size: 15px;
            font-style: normal;
            font-weight: 700;
            line-height: normal; margin-bottom: 10px;}
        

            .btns_call{}

            .hanul{border-radius: 4px;
                border: 1px solid var(--Color-Primary-Blue, #22A9E0);
                box-shadow: 0px 0px 10px 0px rgba(182, 182, 182, 0.50); color: var(--Color-Primary-Blue, #22A9E0);
                text-align: center;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 16px; background: none;}

                .btns_call {
                    margin-top: 40px;
                    justify-content: space-between;
                }

                .btns_call .save_btn, .btns_call .hanul{ width: 48%;}