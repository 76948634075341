.set_leadss {
  padding-left: 0px;
}

.newcomer {
  padding-left: 10px;
}

.leader_set td p {
  color: var(--color-tertiary-grey-mid, #787d8c);
  font-size: 12px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  font-family: "Oxygen", sans-serif !important;
  margin-top: 5px;
  margin-bottom: 0px !important;
}

.leader_set .author-tags {
  font-size: 12px !important;
}
.leader_set .font_set {
  font-size: 14px !important;
}

.dropdown-content p {
  border-radius: 8px;
  background: var(--color-secondary-dark-mid, #3c4150);
  padding: 8px 16px;
  color: #fff !important;

  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
}

/* .dropdown-content::before {
		content: '';
		position: absolute;
		top: 0px;
		left: 7px;
		border-left: 5px solid transparent;
		border-right: 5px solid transparent;
		border-bottom: 5px solid #4a4a4a;
	}


	.dropdown-content {
		position: absolute;
		
	} */

.listing_count {
  color: var(--Color-Tertiary-Grey-mid, #787d8c);

  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.harks {
  padding: 10px !important;
}

.members_listingss span img {
  height: 25px;
  width: 25px;
  border-radius: 100px;
  margin-left: -5px;
}
.members_listingss {
  margin-left: 5px;
}

.numbers_counting {
  color: var(--Color-Tertiary-Grey-light, #b4b9c8);

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  margin-left: 5px;
}

.members_listingss img:not(:first-of-type) {
  margin-left: -20px;
}

.manage_broker_table table {
  width: 100%;
}

.manage_broker_table table th {
  color: var(--Color-Tertiary-Grey-light, #b4b9c8);

  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  background: none;
  padding: 5px 0px;
}

.manage_broker_table td {
  color: var(--Color-Tertiary-Grey, #646978);

  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  padding: 12px 0px;
}

.greener {
  color: #28ac47 !important;
}

.bluerr {
  border-radius: 20px;
  background: #cce4ff;
  color: #007aff;
}
.redner {
  color: #ed4949 !important;
}

.rednerr {
  background: #fbdbdb;
  color: #ed4949;
}
.yellowish {
  color: #ff9600 !important;
}

.mutual_bf {
  padding: 6px 17px !important;
  border-radius: 20px !important;
  font-size: 12px !important;
  display: inline;
  font-weight: 400;
  width: 80%;
  line-height: 150%;
  text-align: center;
  /* margin-right: 20px; */
}

.greener_btn {
  background: #d4eeda !important;
  color: #28ac47;
}

.yellow_btn {
  background: #ffeacc !important;
  color: #ff9600;
}

.blueer {
  color: #007aff !important;
}

.yalner {
  background: #cce4ff;
  color: #007aff;
}

.droppere {
  position: relative;
}
.delete_chat {
  position: absolute;
  z-index: 1;
}
.delete_chat {
  border-radius: 4px;
  background: var(--Color-White-Pure, #fff);
  box-shadow: 0px 0px 30px 0px rgba(80, 143, 244, 0.1);
  padding: 8px;
}
.del_cht {
  background: none;
  border: none;
  color: var(--Color-Tertiary-Grey, #646978);

  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.chat_only {
  margin-top: 6px;
}
.droppere img {
  top: -3px;
  position: relative;
  margin-right: 6px;
  height: 18px;
  width: 18px;
}
.droppere {
  width: 95px;
}

.droppere .upimage {
  cursor: pointer;
}

.manage_broker_table a {
  text-decoration: none;
}
.custom-link {
  cursor: pointer;
}
.kraling {
  margin-left: -9px;
  padding: 0px;
}

.manager {
  margin-top: 4px;
  margin-bottom: 12px;
  font-size: 17px;
  font-family: "Oxygen", sans-serif !important;
  font-weight: bold;
}

@media (min-width: 320px) and (max-width: 1024px) {
  .list_images {
    margin-left: 7px;
  }
}
