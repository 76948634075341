.login-header {
   
    height: 100px !important;
    padding-top: 11px;
}

.mobile {
  
    margin-top: 23px;
}

.right_principal {
    width: 81%;
}
.left_principal {
    width: 15%;
}

.top_principal{ flex-wrap: wrap; justify-content: space-between; border-radius: 10px;
    background: var(--color-white-pure, #FFF);
    box-shadow: 0px 0px 30px 0px rgba(80, 143, 244, 0.10); padding: 32px;}

.right_principal .single_entry label{ font-size: 16px;}

.custom_principal_page{ padding: 50px !important;}

.principal_text_area {
    margin-top: 20px;
}
.principal_text_area h3{ color: var(--color-secondary-dark, #141928);
    font-family: Oxygen;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 24px */}
.principal_text_area .area_control{ color: var(--color-tertiary-grey-mid, #787D8C);

    font-family: Oxygen;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; min-height: 104px !important;}

    .left_principal {
        position: relative;
        text-align: center;
    }
    
    .left_principal img.responsive_image {
        width: auto;
        max-width: 100%;
        height: auto;
        display: block; 
        margin: 0 auto; 
    }
    
    .edit-icon {
        position: absolute;
        top: 50%; 
        left: 50%; 
        transform: translate(-50%, -50%);
        cursor: pointer;
       
    }
    

   
    .responsive_image{ height: 140px !important; width: 140px !important; border-radius: 500px !important;}

    .edit-icon-image {
        height: 30px;
        width: 30px;
        border-radius: 5px;
        background: var(--color-white-off, #F7F8F9);
        margin-top: -10px;
        position: relative;
        top: -40px;
        right: -54px;
    }

        .left_principal  img{ height: 20px; width: 20px;}

        .contact_detail h3{ color: var(--color-secondary-dark-light, #505564);

            /* Heading 6 */
            font-family: Roboto;
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;}

            .left_contact_dt{ width: 48%;}

            .right_cont_dt{ width: 48%;}

            .contact_detail.title_bar_setting {
                width: 100%;
            }

            .contract_rows { justify-content: space-between;}


            .selectors{ margin-top: 50px;}

            .selectors h3{ margin-bottom: 0px !important;}

            .selectors .contract_rows{ justify-content: flex-start;}
            .selectors .left_contact_dt{ width: inherit;}
            .selectors .right_cont_dt{ width: inherit; margin-left: 20px;}









            .custom-radio-container {
    display: inline-block;
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    font-size: 16px;
    user-select: none;
}

.custom-radio-container .custom-radio {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.radio-checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 22px;
    width: 22px;
   
    border-radius: 50%;
    border: 2px solid #80C342; 
}

.custom-radio-container .custom-radio:checked ~ .radio-checkmark {
    background-color: #fff;
}


.radio-checkmark:after {
    content: "";
    position: absolute;
    display: none;
}


.custom-radio-container .custom-radio:checked ~ .radio-checkmark:after {
    display: block;
}


.custom-radio-container .radio-checkmark:after {
    top: 3px;
    left: 3px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: #80C342; 
}



.selector_btn .btn{ border-radius: 4px;
    background: var(--color-primary-green, #80C342) !important;
    box-shadow: 0px 0px 10px 0px rgba(182, 182, 182, 0.50); margin-top: 50px;text-align: center;}

    .selector_btn {
        text-align: center;
        width: 100%;
    }



    /* slider */

    .hidden_selector {
        margin-left: 18px; width: 300px;
    }
    input#myRange {
       
        position: relative;
        top: -10px; width: 300px;
    }



    /* SliderStyles.css */
.rc-slider-track {
    background-color:#80C342 !important;
    height: 10px;
}

.rc-slider-handle {
    border-color: #80C342 !important;
    height: 20px;
    width: 20px;
    margin-left: -10px; 
    margin-top: -5px; 
    background-color: #80C342;
}

.rc-slider-rail {
    background-color: #F0F5FF !important;
    height: 10px;
}

.right_principal .form-control {
   
    display: inline-block;
    margin-left: 10px;
}
.rc-slider-handle {
    position: absolute;
    z-index: 1;
    width: 14px;
    height: 14px;
    margin-top: -5px;
    background-color: #80C342 !important;
    border: solid 2px #80C342 !important;
    border-radius: 50%;
    cursor: pointer;
    cursor: grab;
    opacity: 1 !important;
    touch-action: pan-x;
}

.rc-slider {
    position: relative;
    width: 96% !important;
    height: 14px;
     padding: 0px !important;
    border-radius: 6px;
    touch-action: none;
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin-left: 10px !important;
}




.form-control::placeholder {
    color: #c5c5c5; 
    opacity: 1;
}

@media (min-width:576px) and (max-width:667px) {
    .mobile {
        margin-top: 26px !important;
    }

}


@media (min-width: 320px) and (max-width:575px) {
    .mobile {
        margin-top: -47px !important;
    }

}