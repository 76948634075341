


@media (min-width: 320px) and (max-width:767px) {

    .search_area  input.form-control.me-2 {
        width: 220px;
        height: 41px;
    }

    .listing_initials{}

}

.drop_data {
    position: relative;
    width: 180px !important;
}

.selected_option {
    
    background-position: 152px 10px !important; font-size: 14px;
    
}

.listing_initials {
    height: 40px;
    width: 40px; font-size: 13px;
   
}

.tabhel tr:nth-child(even) {
    background-color: grey !important;  --bs-table-bg: #f8f8f8; /* Define your variable */
}


.farea input.form-control.me-2 {
    width: 250px;
    font-size: 14px;
    height: 40px;
}

.paper{ color: #3C4150 !important; font-size: 14px !important;}

.hallowe {
    height: 40px !important;
    width: 40px !important;
    font-size: 14px !important;
}
