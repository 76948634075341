.margin_headings {
  color: var(--Color-Secondary-Dark-light, #505564);
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.margin_headings span {
  color: var(--Color-Secondary-Dark-light, #505564);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.set_payments {
  padding: 10px !important;
}
.amp-pages h2 {
  margin-left: 0px !important;
}
.single_margin_block {
  margin-left: 16px;
}

.set_payments h2 {
  margin-bottom: 35px;
}

.mutual_blocks h2 {
  margin: 0px !important;
  padding: 0px !important;
}
.paymnent_texting {
  background: url(../../../assets/set-up-pay.svg) no-repeat;
  background-position: 0px 4px;
  padding-left: 32px;
  margin-top: 30px;
  color: var(--Color-Tertiary-Grey-mid, #787d8c);
  font-family: Oxygen;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  /* 24px */
}

.pricing_pay {
  color: var(--Color-Tertiary-Grey-mid, #787d8c);
  font-family: Oxygen;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  margin-left: 4px;
  margin-right: 0px;
}

.bdr_center {
  border-bottom: 1px solid #b4b9c8;
  margin-top: 45px;
  margin-bottom: 49px;
}

/* react-slider */

.horizontal-slider {
  width: 100%;
  height: 5px;
  background: var(--color-white-blue, #f0f5ff);
  border-radius: 10px;
  margin-top: -3px;
}

.thumb {
  height: 15px;
  width: 15px;
  text-align: center;
  background-color: #80c342;
  color: #00aaff;
  border-radius: 50%;
  cursor: grab;
  top: -5px;
}

.track {
  background: transparent;
}

.horizontal-slider .track-0 {
  background: #80c342;
  height: 5px;
  border-radius: 100px;
}

.horizontal-slider .react-slider-track-1 {
  background: var(--color-white-blue, #f0f5ff);
}

.thumb:focus-visible {
  outline: #00aaff auto 1px;
}

.payment_slider {
  display: flex;
  margin-top: 10px;
}

.saver_btn {
  color: var(--Color-White-Pure, #fff);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  border-radius: 4px;
  border: 1px solid var(--Color-Primary-Blue, #80c342);
  background: var(--Color-Primary-Blue, #80c342);
  box-shadow: 0px 0px 10px 0px rgba(182, 182, 182, 0.5);
  padding: 14px 60px;
}

.left_slader .form-control {
  height: 46px;
  border-radius: 5px;
  background: var(--Color-White-Pure, #fff);
  box-shadow: 0px 0px 20px 0px rgba(231, 231, 231, 0.25);
  border: 1px solid #f3f3f3;
  border-radius: 0px;
  -webkit-border-top-left-radius: 5px;
  -webkit-border-bottom-left-radius: 5px;
  -moz-border-radius-topleft: 5px;
  -moz-border-radius-bottomleft: 5px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  padding-left: 35px;
}

.left_slader {
  width: 50%;
  position: relative;
}

.icon_savees {
  position: absolute;
  top: 10px;
  left: 10px;
}

.delete-modal-actions .primary {
  background: #22a9e0 !important;
  border: none;
}

.delete-modal-actions .green-btn {
  background: #80c342 !important;
  border: none;
}

.modal_deklete {
  cursor: pointer;
}

.delete-modal .modal-content {
  border-radius: 15px;
  width: 375px;
}

.delete-modal .modal-header {
  border-bottom: none;
  padding: 1.5rem;
}

.delete-modal .modal-title {
  font-weight: bold;
}

.delete-modal .modal-body {
  padding: 1.5rem;
}

.delete-modal .modal-footer {
  border-top: none;
  padding: 1.5rem;
}

.delete-modal .btn-secondary {
  background-color: #f0f0f0;
  color: #333;
  border-radius: 4px;
  border: 1px solid var(--Color-Tertiary-Grey-light, #b4b9c8);
  background: var(--Color-White-Pure, #fff);
  color: var(--Color-Secondary-Dark, #141928);
  text-align: center;

  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}

.delete-modal .btn-danger {
  background-color: #dc3545;
  color: #fff;
}

.delete-modal .btn {
  border-radius: 5px;
}

.delete-modal .modal-body {
  padding: 50px 30px;
  text-align: center;
  position: relative;
  border-radius: 20px;
  background: #fff;
  box-shadow: 0px 0px 20px 0px rgba(72, 72, 72, 0.2);
}

.delete-modal .modal_deklete {
  position: absolute;
  top: 10px;
  right: 10px;
}

.delete-modal-header img {
  margin-bottom: 50px;
}

.delete-modal-header h5 {
  color: var(--Color-Secondary-Dark, #141928);
  font-family: Roboto;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.delete-modal p {
  color: var(--Color-Tertiary-Grey-mid, #787d8c) !important;
  text-align: center;
  font-family: Oxygen;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  margin-top: 15px;
}

.delete-modal-actions {
  display: flex;
  gap: 20px;
  justify-content: center;
  margin-top: 50px;
}

.delete-modal-actions button {
  min-height: 47px;
  padding: 15px 35px;
  color: var(--Color-White-Pure, #fff);
  text-align: center;

  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  /* 100% */
}

.right_slade {
  margin-left: 15px;
}

.saver_btn {
  cursor: pointer;
}

.margins_blocks {
  margin-top: 34px;
}
