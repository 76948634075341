.custom-link dashboard{
    text-decoration: none;
    color: #333; 
    cursor: pointer; 
  }
  .table-responsivee{
    overflow-x: none;
  }
  .custom-link-row{ cursor: pointer;}
  .right_dash_link a{ color: #3C4150;}


  @media (min-width: 320px) and (max-width:480px) {
    .list_images {
      margin-left: 4px;
  }

  }
  
  .listin_table{ overflow-y: hidden;}

  .na-text {
    display: flex;
    /* justify-content: center !important; */
    /* align-items: center !important; */
    margin: 0; 
    /* width: 100%;  */
    /* height: 100%;  */
  }   
  