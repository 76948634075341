.blog_add {
	text-align: right;
	margin-top: -100px;
	padding: 25px;
}

.posting_area {
	margin-top: 0px;
}

.posting_area table th {
	background: var(--color-secondary-dark-light, #505564);
	color: var(--color-white-pure, #FFF);

	padding: 15px 20px;
	font-size: 14px;


	font-weight: 700;
	line-height: 150%;
}


.posting_area table td {
    padding: 8px 19px;
    vertical-align: middle;
    color: var(--color-secondary-dark-mid, #3C4150);
    font-size: 14.5px;
    font-weight: 400;
    line-height: 150%;
}

.post_page_only .mutual_blocks {
	padding: 0px !important;
}

.author-tags {
	color: var(--color-primary-blue, #22A9E0) !important;

	font-size: 14px !important;
	font-weight: 400;
	line-height: 150%;
}

.font_set {
	font-size: 12px !important;
	color: var(--color-tertiary-grey-mid, #787D8C) !important;
}

.commnts img {
	position: relative;
	margin-right: 7px;
	top: -1px;
}

.dropper .dropdown-toggle::after {
	display: none !important;
}

.dropper .dropdown-menu.show {
	display: block;
	left: -29px;
	background: var(--color-white-off, #F7F8F9);
	box-shadow: 0px 0px 20px 0px rgba(72, 72, 72, 0.20);
	border: none;
	width: 81px;
	height: 104px;

	min-width: 70px;
}

.dropper a {
	font-size: 14px !important;
	padding: 0px;
}

.dropper button {
	font-size: 14px !important;
	padding: 0px;
}

.dropper .dropdown-item {
	line-height: 32px;
	padding: 0px 10px;
}

.dropper .dropdown-item:hover {
	background: #efefef;
}

.posting_area table td {
	border-bottom: none;
}

.posting_area table tr:nth-child(even) {
	background: var(--color-white-off, #F7F8F9);
}

.posting_area table tr:nth-child(odd) {
	background: #efefef;
}

.post_page_only h2 {
	padding: 25px 25px;
}

.padding {
    padding: 0px 17px;
}
.amp-pages h2 {
    font-weight: 700 !important;
    margin-left: 17px !important;
    margin-top: 0px !important;
    padding-top: 11px !important;
    font-size: 26px !important;
}
.link_sett {
	color: var(--color-tertiary-grey, #646978);


	font-size: 16px;

	font-weight: 400;
	line-height: 150%;
}

.link_sett a {
	color: var(--color-tertiary-grey, #646978) !important;
	margin-left: 17px !important;

}

.link_sett a span {
	color: var(--color-primary-blue, #22A9E0) !important;
}

.btn_block button {
	border-radius: 4px;
	border: 1px solid var(--color-primary-blue, #22A9E0);
	box-shadow: 0px 0px 10px 0px rgba(182, 182, 182, 0.50);
	padding: 0px 35px;
	color: var(--color-primary-blue, #22A9E0);
	height: 41px;
	text-align: center;

	font-size: 16px;

	font-weight: 500;
	line-height: 16px;
	background: #fff;
}

.search_bloxk .form-control {
	width: 260px;
	padding: 8px 12px;
	border-radius: 5px;
	border: 1px solid var(--color-white-blue, #F0F5FF);
	height: 41px;
	font-size: 14px;
	padding-right: 13px;
}

.search_bloxk {
	margin-right: -20px;
}


.search_area {
	margin-right: 35px;
}




.admin_packages .posting_area table td {
    border-bottom: none;
    text-align: left;
}


.custom-label {
	position: relative;
	cursor: pointer;
	width: 20px;
	height: 20px;
	background-color: #fff;
	display: inline-block;
	border-radius: 4px;
	border: 2px solid #80C342 ;
	margin: 0 5px; /* Optional, for spacing */
  }
  
  .tivker:checked + .custom-label {
	background-color: #80C342 ;
  }
  
  .tivker:checked + .custom-label::after {
	content: "";
	position: absolute;
	left: 6px;
	top: 1px;
	width: 6px;
	height: 12px;
	border: solid white;
	border-width: 0 3px 3px 0;
	transform: rotate(45deg);
  }
  
  .halfia {
    padding-left: 20px;
}
.cding{ margin-top: 30px;}






  @media (min-width: 1025px) and (max-width:1200px) {

	.Add_new_blog{ margin-top: 50px !important;}
	.Add_new_blog {
		margin-top: 70px !important;
		margin-bottom: -20px;
	}
	.posting_area .paddng{ padding-left: 20px !important;}
	
	.salining{ padding: 0px 25px !important;}
	
		
	  }

  @media (min-width: 768px) and (max-width:1024px) {

	.Add_new_blog{ margin-top: 50px !important;}
	.Add_new_blog {
		margin-top: 70px !important;
		margin-bottom: -20px;
	}
	.posting_area .paddng{ padding-left: 20px !important;}
	
	.salining{ padding: 0px 25px !important;}
	
		
	  }

  @media (min-width: 320px) and (max-width:767px) {

.Add_new_blog{ margin-top: 50px !important;}
.Add_new_blog {
    margin-top: 70px !important;
    margin-bottom: -20px;
}
.posting_area .paddng{ padding-left: 20px !important;}

.salining{ padding: 0px 25px !important;}

	
  }