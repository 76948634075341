.signup_realtor .log_reg {
  justify-content: center;
}

.signup_realtor .log_reg .btn {
  color: var(--color-secondary-dark, #141928);
  text-align: center;

  /* Heading 5 */
  font-family: Roboto;
  font-size: 20px;

  font-weight: 700;
  border-bottom-color: #141928;
}

/* signup-realtor */

/* realtor-profile */

.real_image img {
  height: 80px;
  width: 80px;
  border-radius: 500px;
}

.top_row_real h2 {
  color: var(--color-secondary-dark-mid, #3c4150);

  font-size: 24px;

  font-weight: 700;
}

.top_row_real h2 span {
  color: var(--color-secondary-dark-light, #505564);
  font-family: "Oxygen", sans-serif !important;
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  margin-left: 10px;
}

.top_row_real {
  flex-wrap: wrap;
  justify-content: space-between;
}

.real_teaxing p {
  color: var(--color-tertiary-grey-mid, #787d8c);

  font-size: 14px;

  font-weight: 400;
  line-height: 150%;
  margin-top: 10px;
}

.real_prop_flex.d-flex {
  gap: 28px;
}

.del_active.d-flex {
  gap: 25px;
}

.status_view {
  display: flex;
  padding: 6px 22px;
  /* color: #28ac47; */
  border-radius: 20px;
  /* background: #d4eeda; */
  font-size: 12px;
  font-weight: 400;
  line-height: 150%;
  height: 28px;
}

.contact_realtor {
  margin-top: 23px;
}

.contact_realtor h2 {
  color: var(--color-secondary-dark-light, #505564);
  font-size: 18px;
  font-weight: 700;
}

.cr1 {
  background: url(../../assets/c2.svg) no-repeat;
  background-position: 0px 1px;
}

.cr2 {
  background: url(../../assets/c4.svg) no-repeat;
  background-position: 0px 1px;
}

.cr3 {
  background: url(../../assets/c1.svg) no-repeat;
  background-position: 0px 1px;
}

.cr5 {
  background: url(../../assets/c3.svg) no-repeat;
  background-position: 0px 1px;
}

.left_real_contact {
  color: var(--color-secondary-dark-light, #313646);
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  padding-left: 26px;
  width: 35%;
  margin-top: 19px;
}

.right_real_contact {
  color: var(--color-secondary-dark-light, #505564);
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  padding-left: 26px;
  margin-top: 19px;
}

.verification {
  color: var(--color-tertiary-grey-light, #b4b9c8);
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
  position: relative;
  top: -2px;
}

.ser_areas_realtor {
  margin-top: 30px;
}

.list_con {
  border-radius: 20px;
  background: var(--color-tertiary-grey-mid, #787d8c);
  padding: 5px 12px;
  color: var(--color-white-pure, #fff);
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
}

.area_listing_realtor {
  flex-wrap: wrap;
  gap: 15px;
  margin-top: 20px;
}

.payment_type_status th {
  color: var(--color-tertiary-grey-light, #b4b9c8);
  font-family: Oxygen;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  margin-bottom: 20px;
}

.payment_type_status td {
  color: var(--color-tertiary-grey-mid, #787d8c);
  font-family: Oxygen;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 174%;
  /* 24.36px */
}

.payment_type_status .table-responsive {
  overflow-x: auto;
}

.payment_type_status table {
  width: 100%;
  border-collapse: collapse;
}

.payment_type_status th,
.payment_type_status td {
  padding: 8px;
  text-align: left;
}

.payment_type_status .status {
  color: var(--color-secondary-dark-mid, #3c4150);
  text-align: left;

  /* Heading 4 */
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.payment_type_status .success {
  color: #28ac47;
  font-family: Oxygen;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 0px;
  position: relative;
  top: -10px;
}

.blacking {
  color: var(--color-secondary-dark-light, #505564);
  font-family: Oxygen;
  font-size: 14px;

  font-weight: 700 !important;
  line-height: 174%;
}

.date_set {
  color: var(--color-tertiary-grey, #646978);
  text-align: right;
  font-family: Oxygen;
  font-size: 10px;

  font-weight: 700;
  line-height: 150%;
  /* 15px */
}

.sub_flexing {
  justify-content: space-between;
  flex-wrap: wrap;
}

.halfa {
  width: 19.5%;
}

.new-block {
  border-radius: 10px;
  background: var(--color-white-pure, #fff);
  box-shadow: 0px 0px 30px 0px rgba(80, 143, 244, 0.1);
  padding: 25px 35px;
  min-height: 150px;
}

.new-block h2 {
  color: var(--color-secondary-dark-mid, #3c4150);

  /* Heading 5 */
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.custom-dropdown {
  position: relative;
  width: 100%;
  font-family: Arial, sans-serif;
}

.dropdown-header {
  padding: 10px;
  border: 1px solid #d4eeda;
  border-radius: 4px;
  background-color: #fff;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 20px;
  background: var(--pastel-light-green, #d4eeda);
  color: #28ac47;
}

.dropdown-list-container {
  position: absolute;
  width: 100%;
  z-index: 1000;
}

.dropdown-list {
  list-style: none;
  padding: 0;
  margin: 0;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.dropdown-list li {
  padding: 10px;
  cursor: pointer;
}

.dropdown-list li:hover {
  background-color: #f2f2f2;
}

.dropdown-arrow {
  width: 20px;
  height: 20px;
  /* Other styles for your arrow */
}

/* realtor-profile */

/* activity log */

.row.crow {
  justify-content: center;
}

.custom_activity_only {
  justify-content: left;
}

.dates_calender {
  display: flex;
  gap: 30px;
  flex-wrap: wrap;
}

.overall_m_date {
  justify-content: space-between;
}

.date_setee h3 {
  color: var(--color-tertiary-grey-light, #b4b9c8);
  font-size: 12px;
  font-weight: 400;
}

.date_setee p {
  color: var(--color-secondary-dark-mid, #3c4150);
  font-size: 14px;
  font-weight: 700;
  margin-top: -7px;
}

.date_setee {
  margin-top: 3px;
  margin-left: 8px;
}

.center_bdr {
  height: 30px;
  width: 1px;
  background: #b4b9c8;
  margin-top: 7px;
  margin-left: 20px;
  margin-right: 44px;
}

.custom_activity_only {
  width: 75%;
}

.all_dateses {
  width: 25%;
}

.calendar_icon img {
  cursor: pointer;
}

.all_dateses .react-datepicker__navigation--previous,
.all_dateses .react-datepicker__navigation--next {
  margin-top: 9px;
  color: #fff;
  opacity: 1;
}

/* realtor-popup */

.full_rel_trans {
  flex-wrap: wrap;
}

.left_real_heading {
  font-family: "Roboto", sans-serif !important;
  color: var(--color-secondary-dark-mid, #3c4150);

  font-size: 24px;
  font-weight: 700;
}

.all_realtor_payment_transactions th {
  background: #000;
  padding: 10px 40px !important;
}

.all_transactionsal .modal-body {
  padding: 0px !important;
}

.full_rel_trans {
  padding: 20px;
  padding-top: 35px;
  padding-right: 36px;
}

.all_realtor_payment_transactions td {
  padding: 10px 40px !important;
}

.full_statuse {
  text-align: right;
}

.all_realtor_payment_transactions .success {
  text-align: right;
  position: static;
  font-size: 14px;
  color: #28ac47;
  margin-top: 10px;
  margin-bottom: 13px;
}

.all_realtor_payment_transactions tr:nth-child(even) td {
  background-color: #f7f8f9;
}

.full_rel_trans {
  justify-content: space-between;
}

.custo_real {
  position: relative;
}

.modal_closer {
  position: absolute;
  right: 6px;
  top: 4px;
  cursor: pointer;
}

.details-link {
  cursor: pointer;
}

.all_realtor_payment_transactions .table-responsive {
  height: 500px;
  overflow-y: auto;
}

.all_realtor_payment_transactions th {
  color: #fff;
  font-family: Oxygen;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  margin-bottom: 20px;
}

.payment_type_status td span {
  font-weight: bold;
}

.custom-pagination {
  text-align: center;
  margin-top: 30px;
  margin-bottom: 20px;

  bottom: 300px;
}

.right_real_dropdown .drop_selection h3 {
  color: var(--color-tertiary-grey-light, #b4b9c8);
  font-size: 12px !important;
  font-family: "Oxygen", sans-serif !important;
  font-weight: 400;
  line-height: 150%;
  margin-top: 10px;
  margin-bottom: 6px;
}

.right_real_dropdown .drop_selection {
  padding: 0px 12px;
}

.right_real_dropdown .drop_selection p {
  color: var(--color-tertiary-grey, #646978);
  font-family: "Oxygen", sans-serif !important;
  font-size: 14px;
  font-weight: 400 !important;
  line-height: 26px;
}

.right_real_dropdown .drop_data {
  position: relative;
  width: 190px;
}

.right_real_dropdown .selected_option {
  background-position: 148px 10px !important;
}

.right_real_dropdown .selected_option {
  border-bottom: 1px solid #b4b9c8;
}

.line-charts-data {
  width: 20%;
}
.col-lg-9.jalos {
  margin-top: -10px !important;
  padding-right: 0px;
}
.col-lg-3.col-sm-12.wid_set.getter {
  margin-top: -10px;
}

.activate-modal .modal-body {
  text-align: center;
  padding: 30px;
}

.activate-modal h5 {
  color: var(--Color-Secondary-Dark, #141928);
  text-align: center;
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.activate-modal p {
  color: var(--Color-Tertiary-Grey-mid, #787d8c);
  text-align: center;

  /* Body Normal */
  font-family: Oxygen;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  /* 24px */
}

.activate-modal .cross_btn img {
  height: 24px !important;
  width: 24px !important;
}

.activate-modal .cross_btn {
  top: 0px;
  right: 9px !important;
}

.activate_inactivate_only {
  gap: 7%;
}

.activate_inactivate_only .halks_btn {
  width: 40%;
  border-radius: 4px;
  border: 1px solid var(--Color-Tertiary-Grey-light, #b4b9c8);
  background: var(--Color-White-Pure, #fff);
  color: var(--Color-Secondary-Dark-light, #000);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  cursor: pointer;
  line-height: 16px;
  /* 100% */
}

.activate_inactivate_only .btn_tt_one {
  width: 40%;
  cursor: pointer;
}

.activate-modal .modal-body img {
  height: inherit;
  width: inherit;
}

.activate-modal {
  --bs-modal-width: 372px !important;
}

/* For scroll */
/* .teammate_list {
    background-color: rgb(249, 250, 250);
    max-height: 200px; 
     width: 200px;
    overflow-y: auto; 
  }  */

/* .mater{ height: 355px; overflow-y: scroll;} */
.pp_init {
  height: 54px !important;
  width: 54px !important;
}
.super_class {
  text-align: left !important;
}

.real_teaxing {
  width: 100%;
}

@media (min-width: 1280px) and (max-width: 1580px) {
  .teammate-info h3 {
    line-height: 22px;
  }

  .contact_row_flex.d-flex {
    gap: 80px;
  }
}

@media (min-width: 1150px) and (max-width: 1279px) {
  .mater {
    height: 545px;
    overflow-y: scroll;
  }

  .getter {
    width: 30% !important;
    margin-top: 0px !important;
  }
  .jalos {
    width: 70%;
  }
  .line-charts-data {
    width: 24%;
  }
  .custom_structure.undefined {
    width: 49%;
  }
  .real_teaxing {
    width: 100%;
  }
  .real_image {
    width: 100%;
  }
  .real_prop_flex.d-flex {
    flex-wrap: wrap;
  }
  .top_row_real h2 {
    font-size: 16px;
  }
  .contact_row_flex {
    flex-wrap: wrap;
  }
  .left_real_contact {
    width: 100%;
  }
  .mutual_blocks {
    margin-bottom: 20px;
    margin-top: 20px;
  }
}

@media (min-width: 1280px) and (max-width: 1400px) {
  .new-block {
    border-radius: 10px;
    background: var(--color-white-pure, #fff);
    box-shadow: 0px 0px 30px 0px rgba(80, 143, 244, 0.1);
    padding: 25px 9px;
    min-height: 142px;
  }
}

@media (min-width: 1025px) and (max-width: 1149px) {
  .jalos {
    width: 100%;
  }
  .line-charts-data {
    width: 49%;
  }
  .custom_structure.undefined {
    width: 100%;
  }
  .real_teaxing {
    width: 100%;
  }
  .real_image {
    width: 100%;
  }
  .real_prop_flex.d-flex {
    flex-wrap: wrap;
  }
  .top_row_real h2 {
    font-size: 16px;
  }
  .contact_row_flex {
    flex-wrap: wrap;
  }
  .left_real_contact {
    width: 100%;
  }
  .mutual_blocks {
    margin-bottom: 20px;
    margin-top: 20px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .line-charts-data {
    width: 49%;
  }
  .custom_structure.undefined {
    width: 100%;
  }
  .real_teaxing {
    width: 100%;
  }
  .real_image {
    width: 100%;
  }
  .real_prop_flex.d-flex {
    flex-wrap: wrap;
  }
  .top_row_real h2 {
    font-size: 16px;
  }
  .contact_row_flex {
    flex-wrap: wrap;
  }
  .left_real_contact {
    width: 100%;
  }
  .mutual_blocks {
    margin-bottom: 20px;
    margin-top: 20px;
  }
}

@media (min-width: 320px) and (max-width: 767px) {
  .line-charts-data {
    width: 100%;
  }
  .custom_structure.undefined {
    width: 100%;
  }
  .real_teaxing {
    width: 100%;
  }
  .real_image {
    width: 100%;
  }
  .real_prop_flex.d-flex {
    flex-wrap: wrap;
  }
  .top_row_real h2 {
    font-size: 16px;
  }
  .contact_row_flex {
    flex-wrap: wrap;
  }
  .left_real_contact {
    width: 100%;
  }
  .mutual_blocks {
    margin-bottom: 20px;
  }
}
