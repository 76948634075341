.chat-modal .modal-content {
	border-radius: 20px;
	font-family: 'Arial', sans-serif;
  }
  
  .chat-modal-header {
	background-color: #f0f0f0;
	padding: 10px 20px; 
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-top-left-radius: 20px;
	border-top-right-radius: 20px;
  }
  
  .company-logo {
	border-radius: 50%;
	height: 40px;
	margin-right: 10px;
  }
  
  .header-text {
	flex-grow: 1;
  }
  
  .company-name {
	color: var(--Color-Secondary-Dark-Mid, #3C4150);


font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 150%; 
  }
  
  .last-active {
	color: var(--Color-Tertiary-Grey-light, #B4B9C8);

;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 150%; 
  }
  
  .close-chat {
	cursor: pointer;
	width: 20px; 
  }
  

  
  .chat-input-area {
	display: flex;
	padding: 10px 15px;
	
	border-bottom-left-radius: 20px;
	border-bottom-right-radius: 20px;
  }
  
  .chat-input {
	flex-grow: 1;
	border: none;
	border-radius: 20px;
	padding: 10px 15px;
	margin-right: 10px;
	font-size: 14px; 
  }
  
  .send-button {
	background-color: transparent;
	border: none;
	cursor: pointer;
  }
  
  .chat-message {

	border-radius: 18px;
	padding: 10px 15px;
	margin-bottom: 10px;
	max-width:100%;
	word-wrap: break-word;
  }
  
  .message-left {
	align-self: flex-start;
  }
  
  .message-right {
	align-self: flex-end;
	background-color: #dcf8c6; 
  }
  
  .timestamp-left {
	text-align: left;
	display: block;
	color: #999999;
	font-size: 12px;
	margin-top: 5px;
  }
  
  .timestamp-right {
	text-align: right;
	display: block;
	color: #999999;
	font-size: 12px;
	margin-top: 5px;
  }
  
  

  .chat-modal-content {
	display: flex;
	flex-direction: column;
	height: 100%;
  }
  
  /* Header styles */
  .chat-modal-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 20px;
	border-radius: 20px;
background: var(--Color-White-Off, #F7F8F9);
box-shadow: 0px 0px 20px 3px rgba(0, 0, 0, 0.10);
	
  }
  
  /* Chat messages container styles */
  .chat-messages {
    flex-grow: 1;
    overflow-y: auto;
    padding: 10px 15px;
    height: 65vh;
}
  
  /* Individual message styles */
  .chat-message {
	display: flex;
	align-items: flex-end;
	margin-bottom: 8px;
	font-size: 14px;
	line-height: 1.4; align-items: center;
  }
  
  .chat-message .message-text {
	max-width: 64%;
	padding: 10px;
	border-radius: 7.5px;
	background: var(--Color-White-Off, #F7F8F9);
	border-radius: 20px 3px 20px 20px;
	font-size: 14px;
	word-wrap: break-word;
  }
  
  .chat-message .message-time {
	margin-left: 8px;
	color: var(--Color-Tertiary-Grey-light, #B4B9C8);

/* Body More Small */
font-family: Oxygen;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 150%; /* 18px */
  }
  
  /* Right-aligned messages */
  .chat-message.right .message-text {
	border-radius: 3px 20px 20px 20px;
background: var(--Color-Primary-Green, #80C342); color: #fff;
	margin-left: auto;
  }
  
  .chat-message.right .message-time {
    margin-left: 23px;
    margin-right: 0px;
}
  
  /* Input area styles */
  .chat-input-area {
	display: flex;
	padding: 15px; position: relative;
	
  }
  
  .chat-input-area .chat-input {
	flex-grow: 1;
	border: none;
	border-radius: 18px;
	padding: 10px;
	margin-right: 10px; border-radius: 20px 2px 20px 20px;
	background: var(--Color-White-Off, #F7F8F9);
	box-shadow: 0px 0px 20px 3px rgba(0, 0, 0, 0.10); color: var(--Color-Tertiary-Grey-light, #B4B9C8); padding-right: 52px;

	/* Body More Small */
	font-family: Oxygen;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%; /* 18px */
  }
  
  .chat-input-area .send-button {
    border-radius: 20px 1px 20px 20px;
background: var(--Color-Primary-Green, #80C342);
    border: none;
   
    padding: 6px 12px;
    cursor: pointer;
    position: absolute;
    right: 22px;
}
  

  .comp_info{ display: flex;}








  .chat-modal .modal-dialog{ width: 390px; --bs-modal-width: 390px !important;}

  .chat-modal .modal-body{ padding: 0px;}

  .latest_message{ color: var(--Color-Tertiary-Grey-mid, #787D8C);
	text-align: center;
	
	/* Body Small */
	font-family: Oxygen;
	font-size: 13px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%; padding: 10px 0px;}



	.chat-messages {
		flex-grow: 1;
		overflow-y: auto; 
		padding: 10px 15px;
		height: 65vh;
		-webkit-overflow-scrolling: touch; 
		scrollbar-width: thin;
		scrollbar-color: #4A90E2 #E0E0E0;
	}
	
	::-webkit-scrollbar {
		width: 5px; 
	}
	
	::-webkit-scrollbar-track {
		background: #E0E0E0; 
	}
	
	::-webkit-scrollbar-thumb {
		background: #4A90E2; 
		border-radius: 10px;
	}
	
	::-webkit-scrollbar-thumb:hover {
		background: #3A78D4; 
	}
	