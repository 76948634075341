.teammatess {
  width: 32.5%;
  border-radius: 5px;
  border: 1px solid var(--color-white-blue, #f0f5ff);
  padding: 35px;
  display: flex;
}

.team-mate-page-only {
  flex-wrap: wrap;
}

.team-mate-page-only {
  flex-wrap: wrap;
  /*     justify-content: space-between;
 */
  gap: 20px;
}

.team-mate-page-only .teammate-info h3 {
  color: var(--color-tertiary-grey-mid, #787d8c);

  font-family: Oxygen;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.teammatess a {
  text-decoration: none !important;
}

.team-mate-page-only .sales-link {
  color: var(--color-tertiary-grey-light, #b4b9c8);

  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  /* 24px */
  text-decoration-line: underline;
}

.team-mate-page-only .teammate-img {
  height: 80px;
  width: 80px;
}

.team-matew-page {
  margin-top: 20px;
  margin-bottom: -10px;
}

.team-matew-page h2 {
  color: var(--color-secondary-dark-mid, #3c4150);

  font-size: 24px;

  font-weight: 700;
}

.team-matew-page .right_head {
  color: var(--color-tertiary-grey-mid, #787d8c);

  font-family: Roboto;
  font-size: 16px;

  font-weight: 700;
}

.custom-pagination {
  text-align: center;
  margin-top: 30px;
  margin-bottom: 20px;
}

.custom-pagination ul {
  margin: 0px;
  padding: 0px;
}

.custom-pagination ul li {
  display: inline;
  color: var(--color-tertiary-grey-light, #b4b9c8);
  text-align: center;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 700;
  line-height: 28px;
  padding: 0px 25px;
  cursor: pointer;
}

.rotate_img {
  rotate: 180deg;
}

.active-page {
  color: #fff;
  border-radius: 41px;
  background: var(--color-secondary-dark, #141928);
  height: 32px;
  padding: 10px 22px !important;
}

.results_realtor {
  margin-top: 50px;
}

.realtor_right .update_btn {
  padding: 12px 30px !important;
}

.real_left,
.real_right {
  width: 48%;
}

.real_row {
  justify-content: space-between;
  flex-wrap: wrap;
}

.realtor_modal .modal-body {
  padding: 32px !important;
  padding-top: 20px !important;
}

.real_row {
  margin-bottom: 10px;
}

.real_full_wifth {
  margin-top: 20px;
}

.raltor_btns .raltor_btnss {
  padding: 13px 65px !important;
  width: 50%;
}

.add_realtor_message {
  text-align: center;
  border: none;
  padding-bottom: 0px;
  border-radius: 5px;
  position: relative;
}

.cross_btn {
  position: absolute;
  right: -40px;
  top: -40px;
  cursor: pointer;
}

.add_realtor_message h3::after {
  position: absolute;
  content: "";
  background: #141928;
  height: 2px;
  width: 170px;
  top: 30px;
  left: 101px;
  text-align: center;
  margin: 0 auto;
}

.add_realtor_message h4 {
  font-family: "Oxygen", sans-serif !important;
  color: var(--color-tertiary-grey-mid, #787d8c);
  text-align: center;

  /* Body Small */
  font-family: Oxygen;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  /* 21px */
}

.two_icons {
  gap: 10px;
}

.teammatess .teammate-info {
  padding-left: 10px;
}

.hidden {
  opacity: 0.5;
}

.teammatess .single_icons img[alt="toggle visibility"] {
  /* Styles for the eye icon when visible */
}

.teammatess .hidden .single_icons img[alt="toggle visibility"] {
  /* Styles for the eye icon when hidden, for example, a line over it */
  position: relative;
}

.teammatess .hidden .single_icons img[alt="toggle visibility"]::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  height: 2px;
  background: black;
  /* Or any color you want for the line */
  transform: translateY(-50%);
}

.add_multiple_realt {
  color: var(--color-primary-blue, #22a9e0) !important;
  font-family: "Oxygen", sans-serif !important;
  font-size: 14px;

  font-weight: 700;
  line-height: 150%;
  text-decoration-line: underline;
  margin-top: 10px;
  border: none;
  background: none;
}

.slides_arrows {
  display: flex;
  justify-content: space-between;
}

.slider_serting {
  position: relative;
}

.next_aro img {
  transform: rotate(180deg);
}

.previous_aro {
  position: absolute;
  top: 40%;
  left: -48px;
}

.next_aro {
  position: absolute;
  top: 40%;
  right: -48px;
}

.dot_list {
  height: 8px;
  width: 8px;
  background: #b4b9c8;
  border-radius: 500px;
}

.dot_list.active {
  background: #22a9e0;
}

.dotting {
  width: 100%;
  margin-bottom: 10px;
  display: inline-flex;
  gap: 5px;
  justify-content: center;
  align-items: center;
}

.slides {
  display: none;
}

.slides.active {
  display: block;
}

.slades {
  overflow: hidden;
  position: relative;
}

.slides-container {
  display: flex;
  transition: transform 0.3s ease-in-out;
  /* Adjust timing as needed */
}

.slides {
  flex: 0 0 100%;
  /* Each slide takes 100% of the slider width */
  max-width: 100%;
}

.teammate-initials {
  height: 80px;
  width: 80px;
  background: #efefef;
  border-radius: 100px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: bold;
  font-family: "Oxygen", sans-serif !important;
  color: #919191;
}

.delete_realtor {
  border: 1px solid #dc3545 !important;
  margin-left: 15px;
}

.raltor_btns {
  display: flex;
  justify-content: center;
}

.raltor_btns .raltor_btnss {
  padding: 13px 40px !important;
  width: 37%;
}

@media (min-width: 1280px) and (max-width: 1850px) {
  .teammatess {
    width: 32%;
  }
}

@media (min-width: 1025px) and (max-width: 1279px) {
  .teammatess {
    width: 48.5%;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .teammatess {
    width: 48%;

    padding: 11px;

    margin-top: 15px;
  }
}

@media (min-width: 768px) and (max-width: 1279px) {
  .heading_inline.d-flex.team-matew-page {
    margin-top: 16px;
    margin-bottom: 10px;
  }

  .team-mate-page-only .teammate-info h3 {
    font-size: 16px;
  }

  .custom-pagination ul li {
    font-size: 14px;

    padding: 0px 12px;
  }
}

@media (min-width: 320px) and (max-width: 767px) {
  .team-matew-page .right_head {
    font-size: 14px;
  }

  .teammatess {
    width: 100%;

    padding: 7px;

    margin-top: 14px;
  }

  .heading_inline.d-flex.team-matew-page {
    margin-top: 16px;
    margin-bottom: 10px;
  }

  .team-mate-page-only .teammate-info h3 {
    font-size: 16px;
  }

  .custom-pagination ul li {
    font-size: 14px;

    padding: 0px 12px;
  }
}

@media (min-width: 768px) and (max-width: 955px) {
  .realtor_right .update_btn {
    padding: 12px 27px !important;
  }

  .modal-dialog-centered {
    display: inherit !important;
  }

  .delete-modal .modal-content {
    border-radius: 15px;
    width: inherit !important;
  }
}

@media (min-width: 570px) and (max-width: 767px) {
  .modal-dialog-centered {
    display: inherit !important;
  }

  .delete-modal .modal-content {
    border-radius: 15px;
    width: inherit !important;
  }

  .team-matew-page .right_head {
    font-size: 14px;
    width: 100%;
  }

  .team-matew-page {
    flex-wrap: wrap;
  }

  .d-flex.kala {
    flex-wrap: wrap;
  }

  .realtor_right {
    margin-top: 69px;
  }
}

@media (min-width: 320px) and (max-width: 569px) {
  .modal-dialog-centered {
    display: inherit !important;
  }

  .delete-modal .modal-content {
    border-radius: 15px;
    width: inherit !important;
  }

  .previous_aro {
    left: -40px;
  }

  .next_aro {
    right: -40px;
  }

  .raltor_btns .raltor_btnss {
    padding: 13px 20px !important;
  }

  .team-matew-page .right_head {
    font-size: 14px;
    width: 100%;
  }

  .team-matew-page {
    flex-wrap: wrap;
  }

  .d-flex.kala {
    flex-wrap: wrap;
  }

  .realtor_right {
    margin-top: 69px;
  }
}

/* new-code as per new flow */

.status-actives {
  border-radius: 20px;
  background: #d4eeda;
  padding: 5px 12px;
  color: #28ac47;

  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
  margin-top: 7px;
  display: inline-block;
}

.status-inactives {
  border-radius: 20px;
  background: #cce4ff;
  padding: 5px 12px;
  color: #007aff;

  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
  margin-top: 7px;
  display: inline-block;
}

.status-pendings {
  border-radius: 20px;
  background: #ffeacc;
  padding: 5px 12px;
  color: #ff9600;

  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
  margin-top: 7px;
  display: inline-block;
}

.status-delinquents {
  border-radius: 20px;
  background: #fbdbdb !important;
  padding: 5px 12px;
  color: #ed4949;

  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
  margin-top: 7px;
  display: inline-block;
}

.status-unknowns {
  border-radius: 20px;
  background: #d4eeda;
  padding: 5px 12px;
  color: #28ac47;

  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
  margin-top: 7px;
  display: inline-block;
}

.add_realtor_message .cross_btn {
  top: -30px;
}

.two_tube_btns {
  justify-content: center;
  gap: 4%;
  margin-top: 50px;
}

.btn_tt_one {
  border-radius: 4px;
  background: #22a9e0;
  box-shadow: 0px 0px 10px 0px rgba(182, 182, 182, 0.5);
  min-height: 47px;
  padding: 13px 35px 13px 35px;
  color: var(--Color-White-Pure, #fff);
  text-align: center;

  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  /* 100% */
}

.halks_btn {
  border-radius: 4px;
  background: transparent;
  border-radius: 4px;
  border: 1px solid var(--Color-Primary-Blue, #22a9e0);
  box-shadow: 0px 0px 10px 0px rgba(182, 182, 182, 0.5);
  padding: 13px 35px 13px 35px;
  color: var(--Color-White-Pure, #22a9e0);
  text-align: center;
  width: 48%;
  cursor: pointer;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}

.ok_btn {
  border-radius: 4px;
  background: transparent;
  border-radius: 4px;
  border: 1px solid #80c342;
  box-shadow: 0px 0px 10px 0px rgba(182, 182, 182, 0.5);
  padding: 13px 35px 13px 35px;
  color: #80c342;
  text-align: center;
  width: 48%;
  cursor: pointer;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}

.btn_tt_one {
  width: 48%;
  cursor: pointer;
}

.pending_realtor .modal-body {
  border-radius: 10px;
  background: var(--Color-White-Pure, #fff);
  box-shadow: 0px 0px 30px 0px rgba(80, 143, 244, 0.1);
  padding: 40px;
}

.pending_realtor .modal-body img {
  height: 80px;
  width: 80px;
  margin-top: 10px;
  margin-bottom: 22px;
}

.pending_realtor h2 {
  color: var(--Color-Tertiary-Grey-mid, #787d8c);
  text-align: center;

  /* Labels */
  font-family: Oxygen;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.pending_realtor p {
  color: var(--Color-Tertiary-Grey-mid, #787d8c);

  /* Body Small */
  font-family: Oxygen;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.pending_realtor {
  text-align: center;
}

.pending_realtor {
  --bs-modal-width: 420px !important;
}

.email_verification {
  width: 420px !important;
  border: 1px solid #efefef;
  position: absolute;
  top: 10%;
  left: 44%;
  background: #fff;
}

.haroos {
  top: -12px !important;
  right: -8px;
}

.modal-harddrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  /* Semi-transparent black */
}

.email_verification {
  z-index: 9999999999;
}

.emails_verification h3 {
  color: var(--color-secondary-dark, #141928);
  position: relative;
  margin-bottom: 50px;
  text-align: center;
  font-size: 20px;
  font-weight: 700;
}

.emails_verification p {
  color: var(--color-tertiary-grey-mid, #787d8c);
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
}

.emails_verification img {
  margin: 20px 20px 20px 20px;
}

.emails_verification h4 {
  font-family: "Oxygen", sans-serif !important;
  color: var(--color-tertiary-grey-mid, #787d8c);
  text-align: center;
  font-family: Oxygen;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.emails_verification h3::after {
  position: absolute;
  content: "";
  background: #141928;
  height: 2px;
  width: 170px;
  top: 30px;
  left: 131px;
  text-align: center;
  margin: 0 auto;
}

.faloos {
  top: -32px !important;
  right: -41px !important;
}
